export const NEWS_QUILL_TOOLBAR = [
    [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
];

export const LOGOS_BASE_URL = 'https://cms-api.usacarbon.earth/brandLogos/';
export const COVER_IMAGE_BASE_URL = 'https://cms-api.usacarbon.earth/coverImages/';